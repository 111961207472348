import Product from "../components/Product";
import {graphql} from "gatsby";
import Banner from "../components/Banner";
import Layout from "../components/Layout";
import React from "react";

const AllProducts = ({data}) => {
    const allSanityCategory = data.allSanityCategory.edges.map(e=>e.node)

    return (
        <Layout title={"Products"}>
            <Banner heightSmall={true} backgroundImage={'/media/mv-01.jpg'}>
                <div className={"banner-title"}>
                    <h1 style={{fontSize: "3rem", fontWeight: "bold"}}>All Products</h1>
                </div>
            </Banner>
            <article className="section">
                <div style={{display: "grid", gap: "50px"}}>
                    {allSanityCategory.map(c => {
                        return (
                            <div className={"grid-3-msm"}>
                                <a className="leftmost-accent" href={"/categories/"+c._id}>
                                    <div className="title" style={{fontWeight: "bold"}}>{c.title}</div>
                                    <p>
                                        {c.description}
                                    </p>
                                </a>
                                {c.products.map(p => {
                                    return <Product product={p}/>
                                })}
                            </div>
                        )
                    })}
                </div>
            </article>
        </Layout>
    )
}

export default AllProducts

export const query = graphql`
query {
  allSanityCategory {
    edges {
      node {
        _id
        title
        _rawDescription
        products {
          _id
          title
          image {
            ...ImageWithPreview
          }
          _rawDescription
          link
        }
      }
    }
  }
}
`
